.article-category-container{
    display: flex;
    flex-direction: column;
    align-content: center;
    flex-wrap: nowrap;
    width: 20vw;
    padding: 10px;
}

.photo-category-container{
    width:20vw;
}

.article-category-title {
    color: #1ea79f;
    font-size: large;
    cursor: pointer;
    margin: 15px;
}

.article-category-content{
    margin-top: 0.5em;
    font-size: 1em;
    margin-bottom: 1em;
    box-sizing: inherit;
    margin-left: 15px;
}


@media (min-width: 421px) and (max-width: 1023px){
    .article-category-container{
        display: flex;
        flex-direction: column;
        align-content: center;
        flex-wrap: nowrap;
        width: 25vw;
        padding:20px;
    }

    .photo-category-container{
        width:25vw;
    }
}

@media (max-device-width: 420px) {

    .article-category-container{
        display: flex;
        flex-direction: column;
        align-content: center;
        flex-wrap: nowrap;
        width: calc(80vw - 20px);
        padding:20px;
        margin-bottom: 3vw;
    }

    .article-category-title {
        color: #1ea79f;
        font-size: large;
        cursor: pointer;
        margin: 15px 15px 15px 0px;
    }

    .photo-category-container{
        display: flex;
        justify-content: center;
        width: 75vw;
    }

    .article-photo {
        float: left;
        width:  inherit;
        height: 20vh;
        object-fit: cover;
        cursor: pointer;
    }

    .article-category-content{
        font-size: 1em;
        box-sizing: inherit;
        margin: 0px 15px 1em 0px
    }
}